import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { AxiosResponse, AxiosRequestConfig } from 'axios';


export class statisticsApi {
  [key: string]: any;

  public value: any;

  @Inject(HttpService) private http!: HttpService;

  // 20220714 数据分析
  // 数据分析模块接口集锦
  //   // 关键字统计按分页查询
  public keywordList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/keyword-statis/page-list', param);
  }
  //   // 关键字单个列表下载为excel
  public async downExcelSingle(param: Dict<any>, name: string): Promise<any> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/keyword-statis/download?' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/keyword-statis/download?' + param, name + '.xlsx');
  }
  //   // 关键字全部要下载为excel
  public async downExcel(param: Dict<any>, name: string): Promise<any> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/keyword-statis/download-list?' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/keyword-statis/download-list?' + param, name + '.xlsx');
  }
  // d历史消息分析 按分页查询
  public historyList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/history-statis/page-list', param);
  }
  // d历史消息分析 全部导出
  public async historyAllDownload(param: Dict<any>, name: string): Promise<any> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/history-statis/download-list?' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/history-statis/download-list?' + param, name + '.xlsx');
  }
  // d历史消息分析 单个导出
  public async historyDownload(param: Dict<any>, name: string): Promise<any> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/history-statis/download?' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/history-statis/download?' + param, name + '.xlsx');
  }

  // 图文分析
  // 按条件分页查询list
  public getImgTextList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/statis/page-list', param);
  }
  // 图文 单个 统计列表下载为excel
  public async ImgTextDownloadSingle(param: Dict<any>, name: string): Promise<any> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/statis/download/' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/statis/download/' + param, name + '.xlsx');
  }

  // 图文 全部 统计列表下载为excel
  public async ImgTextDownloadAll(param: Dict<any>, name: string): Promise<any> {
    // return this.http.get('/api/application/statis/download-list', param);
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/statis/download-list?' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/statis/download-list?' + param, name + '.xlsx');
  }

  //诺小二AI 操作明细
  //按条件分页查询list查询list
  public getBuddyAIList(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-buddy-backend/api/user/admin/pageView', param);
  }

  //诺小二AI 按条件导出 统计列表下载为excel
  public async BuddyAIDownloadByCondition(param: Dict<any>, name: string): Promise<any> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.post('/wecom-buddy-backend/api/user/admin/export/excel', param,null,resType);
    this.http.triggerDownload(blob, '/wecom-buddy-backend/api/user/admin/export/excel?' , name + '.xlsx');
  }

  // 专栏分析
  // 按条件分页查询list
  public getcolumnList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/agent-statis/page-list', param);
  }
  // 专栏 全部 统计列表下载为excel
  public async columnDownloadAll(param: Dict<any>, name: string): Promise<any> {
    // return this.http.get('/api/application/statis/agent-download-list', param);
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/agent-statis/download-list?' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/agent-statis/download-list?' + param, name + '.xlsx');
  }
  // 用户分析
  public getPersonList(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/userAnalysis/list', param);
  }
  // 用户分析 全量导出
  public async personDownloadAll(param: Dict<any>, name: string): Promise<any> {
    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/userAnalysis/export?' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/userAnalysis/export?' + param, name + '.xlsx');
  }

  // 自动回复接口数据 Reply
  // 查询所有
  public getAllReply(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/autoReply/list', param);
  }
  // 具体查询
  public getReply(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/autoReply/query', param);
  }
  // 保存  新增和修改
  public saveReply(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/autoReply/one', param);
  }
  //关键字  删除
  public deleReply(param: Dict<any>): Promise<any> {
    return this.http.delete(`/api/application/autoReply/${param.id}`);
  }

  // 关键字列表
  public getkeywordList(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/application/autoReply/keyword-list/${param}`);
  }
  // /api/application/autoReply/keyword-exist
  // 根据agentId判断某专栏下关键字是否存在
  public checkKeyword(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/autoReply/keyword-exist', param);
  }
  // 查询单个 Get方式 无keywordList
  public getsingleReply(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/application/autoReply/${param}`);
  }
  // 查询单个 Post方式 有keywordList
  public getsingleReplyPost(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/autoReply/single', param);
  }

  // 群发消息 单个导出
  public async errorDownload(param: Dict<any>, name: string): Promise<any> {

    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/sendMessagesGroup/errorUsers/' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/sendMessagesGroup/errorUsers/' + param, name + '.xlsx');
  }
  // 小诺密码服务 查询获取列表
  public getpasswordList(param: Dict<any>): Promise<any> {
    return this.http.get('/api/passwordReset/list', param);
  }
  // 小诺密码服务 完成处理
  public editSure(param: Dict<any>): Promise<any> {
    return this.http.post('/api/passwordReset/update', param);
  }
  // SWIFT 日志 员工
  public getswiftlogList(param: Dict<any>): Promise<any> {
    // return this.http.get('/api/application/swift/log/list', param);
    return this.http.post('/api/application/swift/log/emps', param);

  }
  // SWIFT 日志 岗位
  public getswiftlogListG(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/swift/log/posts', param);
  }
  // SWIFT 日志 同步
  public getswiftlogSync(): Promise<any> {
    return this.http.get('/api/application/swift/log/sync');
  }
  // SWIFT 日志 同步 状态查询
  public getswiftlogSyncstatus(): Promise<any> {
    return this.http.get('/api/application/swift/log/syncstatus');
  }
  // mi医学问询 日志
  public getMiList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/mi/list', param);
  }
  // mi医学问询 日志重新发送
  public MisendAgain(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/mi/sendMail', param);
  }
  // role play RolePlay导出
  public async RPdownLoad(param: Dict<any>, name: string): Promise<any>{

    const resType: AxiosRequestConfig = { responseType: 'blob' };
    const blob = await this.http.get('/api/application/role/play/users/downLoad/' + param, undefined, resType);
    this.http.triggerDownload(blob, '/api/application/role/play/users/downLoad/' + param, name + '.xlsx');
    // return this.http.get('/api/application/role/play/users/downLoad');
  }

  // public async errorDownload(param: Dict<any>, name: string): Promise<any> {
  //   const resType: AxiosRequestConfig = { responseType: 'blob' };
  //   const blob = await this.http.get('/api/application/sendMessagesGroup/errorUsers/' + param, undefined, resType);
  //   this.http.triggerDownload(blob, '/api/application/sendMessagesGroup/errorUsers/' + param, name + '.xlsx');
  // }

  // role play RolePlay上传
  public RPUpload(param: Dict<any>): Promise<any> {
    const blob = this.http.post('/api/application/role/play/users/upload', param,undefined,{ headers: { 'Content-Type': 'multipart/form-data'}});
    console.log('blob',blob)
    return blob
  }
  // role play RolePlay列表查询
  public getuserspage(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/role/play/users/page', param);
  }
  //医学问询导出
  public mimanageExportReport(param: Dict<any>): Promise<any> {
    return this.http.downloadFiles('/api/application/mi/miDownLoad', {}, param,'医学问询管理报表.xlsx');
  }
  // 列表里的批量导入中的上传
  public surveyUpload(param: Dict<any>): Promise<any> {
    const blob = this.http.post('/wecom-survey-api/admin/servey/upload', param,undefined,{ headers: { 'Content-Type': 'multipart/form-data'}});
    console.log('surveyUpload',blob)
    return blob
  }
  //附件批量上传
  public surveyBatchUpload(param: Dict<any>): Promise<any> {
    const blob = this.http.post('/wecom-survey-api/survey/attachment/batch/upload', param,undefined,{ headers: { 'Content-Type': 'multipart/form-data'}});
    return blob
    //return this.http.post('/wecom-survey-api/survey/attachment/batch/upload', param,undefined,{ headers: { 'Content-Type': 'multipart/form-data'}});
  }


  //附件批量上传
  public fileBatchUpload(param: Dict<any>): Promise<any> {
    const blob = this.http.post('/wecom-survey-api/admin/servey/saveSurveyFiles', param,undefined,{ headers: { 'Content-Type': 'multipart/form-data'}});
    console.log('surveyBatchUpload',blob)
    return blob
    //return this.http.post('/wecom-survey-api/survey/attachment/batch/upload', param,undefined,{ headers: { 'Content-Type': 'multipart/form-data'}});
  }
  //
  public importsSurveyDropDown(param: Dict<any>): Promise<any> {
    const blob = this.http.get('/wecom-survey-api/admin/servey/importsSurveyDropDown', param,);
    console.log('surveyBatchUpload',blob)
    return blob

  }


}
